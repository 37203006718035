import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import ApiClient from "../../../methods/api/apiClient";

// SignUp Modal
import { Formik } from "formik";
import { toast } from "react-toastify";
import loader from "../../../methods/loader";
import { useDispatch, useSelector } from "react-redux";
import { active_Plan } from "../../../actions/activePlan";
import { login_success } from "../../../actions/user";
import methodModel from "../../../methods/methods";
import environment from "../../../environment";

const Html = ({ Logout, history, token, urlPath }) => {
  let activePlan = useSelector((state) => state.activePlan);
  const [data, setdata] = useState();
  const { slug } = useParams();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [Freeplan,SetFreeplanId]=useState([])
  const user = useSelector((state) => state.user);
  const code = methodModel.getPrams("code");
  const [activeTab, setactiveTab] = useState("");
  const [Total, setTotal] = useState(0)

  const pagesName = () => {
    if (urlPath == "/app/qrcodes" || urlPath == `/app/qrcodes/${slug}`) {
      return "New QR Code";
    } else if (urlPath == "/app/accountsetting") {
      return "Settings";
    } else if (urlPath == "/app/qrscanner") {
      return "QR Scanner";
    } else if (urlPath == "/app/trash") {
      return "Trash";
    } else if (
      urlPath == "/app/folder" ||
      urlPath == "/app/folder/add" ||
      urlPath == `/app/folder/edit/${id}`
    ) {
      return "Folders";
    } else if (urlPath == "/app/qrcodelisting") {
      return "QR Codes";
    } else if (
      urlPath == "/app/template" ||
      urlPath == "/app/template/add" ||
      urlPath.includes("/app/template/edit")
    ) {
      return "Templates";
    } else if (urlPath.includes("/app/qrcode/edit")) {
      return data?.folderName || "--";
    } else if (urlPath == "/app/referral") {
      return "Referral & Affiliate Program";
    } else if (urlPath == "/app/analytics") {
      return "Analytics";
    } else if (urlPath == "/app/bulkqr") {
      return "New Bulk QR Code";
    }
  };

  const url = () => {
    if (
      urlPath == "/app/bulkqr" ||
      urlPath == "/app/analytics" ||
      urlPath == "/app/referral" ||
      urlPath.includes("/app/qrcode/edit") ||
      urlPath == "/app/qrcodes" ||
      urlPath == `/app/qrcodes/${slug}` ||
      urlPath == "/app/accountsetting" ||
      urlPath == "/app/qrscanner" ||
      urlPath == "/app/trash" ||
      urlPath == "/app/folder" ||
      urlPath == "/app/folder/add" ||
      urlPath == `/app/folder/edit/${id}` ||
      urlPath == `/app/qrcodelisting` ||
      urlPath == "/app/template" ||
      urlPath == "/app/template/add" ||
      urlPath.includes("/app/template/edit")
    ) {
      return true;
    } else {
      return false;
    }
  };

  const childPath = () => {
    if (
      urlPath == `/app/folder/edit/${id}` ||
      urlPath == "/app/template/add" ||
      urlPath.includes("/app/template/edit") ||
      (urlPath.includes("/app/qrcode/edit") && !data?.qrType)
    ) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (id && urlPath == `/app/folder/edit/${id}`) {
      ApiClient.get(`folder?id=${id}`).then((res) => {
        if (res.success) {
          setdata(res?.data?.data[0]);

        }
      });
    } else if (urlPath == "/app/template/add") {
      setdata({ ...data, name: "New Template" });
    } else if (urlPath.includes("/app/template/edit")) {
      ApiClient.get(`template?id=${id}`).then((res) => {
        if (res.success) {
          setdata(res?.data);
        }
      });
    } else if (urlPath.includes("/app/qrcode/edit")) {
      ApiClient.get(`qrcode/download?slug=${slug}`).then((res) => {
        if (res.success) {
          setdata({
            ...data,
            name: res?.data?.setting?.qrCodeName || "",
            folderName: res?.data?.setting?.folderName || "",
            qrType: res?.data?.staticQrcode,
          });
        }
      });
    }
    // For Active Tab
    if (urlPath == "/qr-code-scanner") {
      setactiveTab("QrCodeScanner");
    } else if (urlPath == "/bulk-qr-code") {
      setactiveTab("BulkQrCode");
    } else if (urlPath == "/faq") {
      setactiveTab("FAQ");
    } else if (urlPath == "/blog") {
      setactiveTab("Blog");
    } else if (urlPath == "/contact") {
      setactiveTab("Support");
    } else if (urlPath == "/pricing") {
      setactiveTab("Pricing");
    } else {
      setactiveTab("");
    }
  }, []);


  useEffect(() => {
    if (user.id) {
      ApiClient.get(`folder/all?user_id=${user?.id}`).then((res) => {
        if (res.success) {
          setTotal(res?.data?.total)
        }
      })
    }
  }, [])

  const getAllPlans = () =>{
    ApiClient.get(`subscription-plan/all`).then((res) => {
      if (res.success) {
        const data = res?.data?.data?.filter((item)=>item?.name == "premium")
        SetFreeplanId(data)
      }
    });
    }
  
    useEffect(()=>{
      getAllPlans()
    },[])




  // Signup Modal
  const [eyes, setEyes] = useState({
    password: false,
    confirmPassword: false,
    currentPassword: false,
  });

  return (
    <>
      <div className="container-fluid px-0 border border-1 header_sticky">
        <nav className="navbar navbar-expand-lg bg-white">
          <div className="container-fluid">
            <a className="navbar-brand">
              <img
                onClick={() => history("/")}
                src="/assets/img/favicon_2.png"
                width="150px"
                alt="..."
              />
            </a>
            {/* <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          </button> */}
            <span
              class="material-icons navbar-toggler menu_btn"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              menu
            </span>
            <div
              className="collapse navbar-collapse box_navbar"
              id="navbarSupportedContent"
            >
              {url() ? (
                <div className="d-flex align-items-center width_div m-auto">
                  <i className="material-icons fs-3">home</i>
                  <i className="material-icons mx-1 fs-6">arrow_forward_ios</i>
                  <span className={`${childPath() ? "" : "fw-bold"} fs-5`}>
                    {pagesName()}{" "}
                    {childPath() ? (
                      <span className="text-capitalize fw-bold">
                        <i className="material-icons mx-1 fs-6">
                          arrow_forward_ios
                        </i>{" "}
                        {data?.name}
                      </span>
                    ) : (
                      ""
                    )}
                  </span>
                </div>
              ) : (
                <ul className="navbar-nav mx-auto mb-2 mb-lg-0 ul-nav">
                  <li className="nav-item">
                    {/* Class Name = active */}
                    <Link
                      className={`nav-link px-4 linK_padding fw-bold ${activeTab == "QrCodeScanner" && "active_header_tab"
                        }`}
                      aria-current="page"
                      to={`/qr-code-scanner`}
                    >
                      QR Code Scanner
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={`nav-link px-4 linK_padding fw-bold ${activeTab == "BulkQrCode" && "active_header_tab"
                        }`}
                      aria-current="page"
                      to={`/bulk-qr-code`}
                    >
                      Bulk QR Codes
                    </Link>
                  </li>
                  <li className={`nav-item `}>
                    <Link
                      className={`nav-link px-4 linK_padding fw-bold ${activeTab == "FAQ" && "active_header_tab"
                        }`}
                      aria-current="page"
                      to={`/faq`}
                    >
                      FAQ
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={`nav-link px-4 linK_padding fw-bold ${activeTab == "Blog" && "active_header_tab"
                        }`}
                      aria-current="page"
                      to={`/blog`}
                    >
                      Blog
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={`nav-link px-4 linK_padding fw-bold ${activeTab == "Pricing" && "active_header_tab"
                        }`}
                      aria-current="page"
                      to={`/pricing`}
                    >
                      Pricing
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={`nav-link px-4 linK_padding fw-bold ${activeTab == "Support" && "active_header_tab"
                        }`}
                      aria-current="page"
                      to={`/contact`}
                    >
                      Support
                    </Link>
                  </li>
                </ul>
              )}
              {urlPath == `/app/qrcode/edit/${slug}` ? (
                <div className="d-flex">
                  <button
                    onClick={() => history("/app/qrcodelisting")}
                    className="btn btn-clear border w-100 mt-2"
                  >
                    {" "}
                    Back
                  </button>
                </div>
              ) : null}
              {urlPath == `/app/folder/edit/${id}` ? (
                <div className="d-flex">
                  <button
                    onClick={() => history("/app/folder")}
                    className="btn btn-clear border w-100 mt-2"
                  >
                    {" "}
                    Back
                  </button>
                </div>
              ) : null}
              {token ? (
                <div className="d-flex ms-2">
                  {/* {url() ?
                  null
                  :
                  <button type="button" className="btn btn-primary d-flex" onClick={e => { history(`/app/qrcodes`) }}>My Dashboard <i className="material-icons ms-1 text-light">dashboard</i></button>
                } */}
                  {urlPath == "/app/folder" ? (

                    <button
                      type="button"
                      onClick={(e) => {


                        {
                          Total < activePlan?.max_folders ? history(`/app/folder/add`) :
                            toast.error("Your Folder Plan Limit has been reached")
                        }
                      }}
                      className="btn btn-primary ms-3 d-flex align-items-center btn_line"
                    >
                      New Folder{" "}
                      <i className="material-icons ms-2 text-white">
                        create_new_folder
                      </i>
                    </button>

                  ) : urlPath == "/app/template" ? (
                    <button
                      type="button"
                      onClick={(e) => {
                        history(`/app/template/add`);
                      }}
                      className="btn btn-primary ms-3 d-flex align-items-center btn_line"
                    >
                      New Template{" "}
                      <i className="material-icons ms-2 text-white">
                        add_circle
                      </i>
                    </button>
                  ) : url() ? (
                    <button
                      type="button"
                      className="btn btn-danger w-100 mt-2"
                      onClick={(e) => Logout()}
                    >
                      Logout
                    </button>
                  ) : null}
                </div>
              ) : (
                <form className="d-flex gap-3 f_size" role="search">
                  <button
                    type="button"
                    className="btn btn-outline-primary fw-bold"
                    onClick={(e) => {
                      history(`/login`);
                    }}
                  >
                    Login
                  </button>
                  {/* <button type="button" className="btn btn-primary fw-bold" onClick={e => { history(`/signup`) }}>Sign Up Free</button> */}
                  <button
                    type="button"
                    className="btn btn-primary fw-bold"
                    id="OpenSignUpModal"
                    data-bs-toggle="modal"
                    data-bs-target="#signupModal"
                  >
                    Sign Up Free
                  </button>
                </form>
              )}
            </div>
          </div>
        </nav>
        <ToastContainer position="top-right" />
      </div>

      {/* SignUP Modal */}
      <div
        className="modal fade"
        id="signupModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered w-75">
          <div className="modal-content modal_signup">
            <div className="modal-body p-0">
              <div className="row align-items-center  justify-content-center">
                <div className="col-md-12 col-lg-6 d-none d-lg-block border-right">
                  <div className="sign-div sign_div-modal">
                    <h4 className="fw-bold text-start text-dark lh-base track_box">
                      Create, Manage, and Track <br></br>{" "}
                      <a className="" href="#" role="button">
                        all your QR Codes{" "}
                      </a>{" "}
                      in one account
                    </h4>
                    <div className="done_ixons">
                      <p>
                        <span class="material-icons">done</span> Optimize the
                        performance of your QR Codes{" "}
                      </p>
                      <p>
                        <span class="material-icons">done</span>Save QR Code
                        templates{" "}
                      </p>
                      <p>
                        <span class="material-icons">done</span>Set password,
                        expiry, geofencing, and more{" "}
                      </p>
                    </div>
                    <a className="" href="#" role="button">
                      <img
                        src="./assets/img/Frame 237.png"
                        className="img-fluid"
                        alt="..."
                      />
                    </a>
                  </div>
                </div>
                <div className="col-md-12 col-lg-6">
                  <div className="login-bx bg-white login_bx-modal">
                    <Formik
                      enableReinitialize
                      initialValues={{ email: "", password: "" }}
                      onSubmit={(values) => {
                        let payload = {
                          email: values.email,
                          password: values.password,
                          role: "user",
                        };
                        if (code) {
                          payload = {
                            ...payload,
                            referral_slug: code,
                          };
                        }
                        loader(true);
                        ApiClient.post(`register`, payload).then((res) => {
                          if (res.success) {
                            ApiClient.post(`user/auto-login`, {
                              id: res?.data?.id,
                            }).then((response) => {
                              if (response.success) {
                                localStorage.setItem(
                                  "token",
                                  response.data.access_token
                                );
                                dispatch(login_success(response.data));
                                setTimeout(() => {
                                  toast.success("Successfully Login");
                                }, 100);
                                let payload = {
                                  id: Freeplan?.[0]?.id || Freeplan?.[0]?._id,
                                  stripe_price_id:Freeplan?.[0]?.stripe_price_id,
                                  user_id: response?.data?.id,
                                };
                                ApiClient.post(`subscribe`, payload).then(
                                  (result) => {
                                    if (res.success) {
                                      dispatch(active_Plan(result?.data));
                                    }
                                  }
                                );
                                if (
                                  urlPath == `/${slug}` ||
                                  urlPath == "/urls"
                                ) {
                                  history(
                                    `/app/qrcodes/${slug ? slug : "urls"}`
                                  );
                                } else if (urlPath == "/bulk-qr-code") {
                                  history("/app/bulkqr");
                                } else {
                                  history(`/app/qrcodes`);
                                }
                              }
                            });
                            document.getElementById("CloseSignUpModal").click();
                          }
                          loader(false);
                        });
                      }}
                    >
                      {({ values, handleChange, handleBlur, handleSubmit }) => (
                        <form className="centerLogin" onSubmit={handleSubmit}>
                          <div className="mb-2">
                            <h4 className="text-left fw-bold text-dark mb-0">
                              Please sign up to continue.
                            </h4>
                          </div>
                          <div className="h_features h_modal">
                            <h6 className="text-left mx-0">
                              No credit card needed!
                            </h6>
                          </div>
                          <div className="text-start">
                            <label className="form-label">
                              Email<span className="text-danger">*</span>
                            </label>
                            <input
                              type="email"
                              value={values.email}
                              required
                              name="email"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              placeholder="Email"
                              className="form-control bginput"
                            />
                          </div>
                          <div className="mt-3 text-start position-relative set-pass">
                            <label className="form-label">
                              Password<span className="text-danger">*</span>
                            </label>
                            <input
                              className="form-control bginput"
                              type={!eyes.password ? "password" : "text"}
                              required
                              value={values.password}
                              name="password"
                              minLength="8"
                              onChange={handleChange}
                              placeholder="Password"
                              onBlur={handleBlur}
                            />
                            <i
                              className={
                                eyes.password
                                  ? "fa fa-eye setfa"
                                  : "fa fa-eye-slash setfa"
                              }
                              onClick={() =>
                                setEyes({ ...eyes, password: !eyes.password })
                              }
                            ></i>
                          </div>
                          <div className="mt-4 d-flex align-items-baseline">
                            <p>
                              By signing up, you agree to the
                              <Link
                                className="m-sign ps-1"
                                to="/terms"
                                onClick={(e) => {
                                  document
                                    .getElementById("CloseSignUpModal")
                                    .click();
                                }}
                                role="button"
                              >
                                Terms and Conditions
                              </Link>{" "}
                              and
                              <Link
                                className=" m-sign ps-1"
                                to="/privacy"
                                onClick={(e) => {
                                  document
                                    .getElementById("CloseSignUpModal")
                                    .click();
                                }}
                                role="button"
                              >
                                Privacy Policy.
                              </Link>
                            </p>
                          </div>
                          <div>
                            <button
                              type="submit"
                              className="btn btn-primary w-100 mt-3"
                            >
                              Sign Up Free
                            </button>
                          </div>
                          {/* <div className='borderCls or-bx text-center my-3'>Or</div> */}
                          {/* <a className='btn btn-outline google_id w-100'> */}
                          {/* <button type='button' className='google_heading border-1 rounded py-2 bg-transparent w-100'><img src='/assets/img/gogle.png' className='m_img' />Continue with Google</button> */}
                          {/* </a> */}
                          <p className="accop my-3">
                            Already have an account?
                            <Link
                              className="sign_up m-sign"
                              onClick={(e) => {
                                document
                                  .getElementById("CloseSignUpModal")
                                  .click();
                              }}
                              to="/login"
                            >
                              Login
                            </Link>
                          </p>
                        </form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
            <button
              type="button"
              id="CloseSignUpModal"
              className="btn btn-secondary d-none"
              data-bs-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Html;
